.page-container {
	min-height: 100vh;
	padding-left: $side-nav-width;
	@include transition(all 0.2s ease); 
	@include print {
		padding-left: 0px;
	}

	@include screen-laptop-only {
		padding-left: $side-nav-width-laptop;
	}

	@include screen-tablet {
		padding-left: 0px;;
	} 

	.main-content {
		padding: calc(#{$header-height} + #{$layout-content-gutter}) $layout-content-gutter $layout-content-gutter;
		min-height: calc(100vh - #{$header-height});

		&.full-container {
			padding: 0px;
			padding-top: 95px;
		}

		@include print {
			padding: 10px 0px;
		}

		@include screen-tablet {
			padding: 85px 10px 10px 10px;
		}	
	}

	.page-header {
		margin-bottom: 30px;

		&.no-gutters {
			background-color: $white;
			border-bottom: 1px solid $border-color;
			padding: $layout-content-gutter 30px;
			margin-top: -$layout-content-gutter;
			margin-left: -$layout-content-gutter;
			margin-right: -$layout-content-gutter;
			margin-bottom: $layout-content-gutter;

			&.has-tab {
				padding-bottom: 0px;
			}
		}

		.header-title {
			display: inline-block;
			font-size: 17px;
			margin-bottom: 0px;
			padding-bottom: 5px;
			margin-right: 20px;
			line-height: 1; 
			color: $gray;

			@include screen-mobile {
				border-right: 0px;
				display: block;
				margin-bottom: 15px;	
			} 
		}

		.header-sub-title {
			display: inline-block;

			@include screen-mobile {
				padding-left: 0px;
				display: block;	
			} 
		}
	}
}

.is-folded {

	@include screen-tablet-above {
		.page-container {
			padding-left: $side-nav-folded;
		}
	}	

	@include screen-tablet {
		.page-container {
			padding-left: 0px;
		}
	}
}
