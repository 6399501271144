@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,700');

html, html a, body {
   -webkit-font-smoothing: antialiased;
}

body {
   font-family: $font-family-base;
   font-size: 14px;
   background-color: $body-bg;
   color: $gray;
   line-height: 1.5;
   overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	color: $gray-dark;
	font-weight: normal;
	line-height: 1.5;
	font-weight: $font-weight-semibold;
	
	small {
		font-weight: $font-weight-light;
		color: lighten($gray-dark,5%);
	}
}

h1,
.h1 {
    font-size: 30px;
}

h2,
.h2{
    font-size: 24px;
}

h3,
.h3 {
    font-size: 22px
}

h4,
.h4 {
    font-size: 19px
}

h5,
.h5 {
    font-size: 16px;
}

h6,
.h6 {
    font-size: 14px;
}

p {
	color: $gray-light;
	line-height: 1.8;
	margin-bottom: 10px;
}

ul {
	margin-bottom: 0px;
}

a {
	color: $brand-primary;
	
	&:hover,
	&:focus {
		text-decoration: none;
		color: darken($brand-primary,10%);
	}

	&:focus {
		outline: none;
	}

	&.text-gray {

		&:hover,
		&:focus {
			color: $gray-dark !important;
		}

		&.active {
			color: $brand-primary !important;
		}
	}

	&.text-dark {
		&:hover,
		&:focus {
			color: $brand-primary !important;
		}

		&.active {
			color: $brand-primary !important;
		}
	}

	&.text-muted {
		&:hover,
		&:focus {
			color: $gray-dark !important;
		}

		&.active {
			color: $brand-primary !important;
		}
	}

	&.hover-opacity {
		&:hover {
			opacity: 0.8;
		}
	}
}

.display-1,
.display-2,
.display-3,
.display-4 {
	color: $gray-dark;
}

:focus {
	outline: none;
}

hr {
	border-top: 1px solid $border-color;
	margin-top: 24px;
    margin-bottom: 24px;
}

.font-primary {
	font-family: $font-family-base;
}

.text-link {
	
	&:hover,
	&:focus {
		text-decoration: underline;
	}
}

.text-opacity {
	opacity: 0.85;
}

.text-white {
	color: $white !important;
}

.text-dark {
	color: $gray-dark !important;
}

.text-gray {
	color: $gray-light !important;
}

// Loop for contextual text-color 
@each $type in $contextual-loop {
	.#{nth($type, 3)} {
		color: #{nth($type, 1)} !important;
	}
}	

.bg-white {
	background-color: $white !important;
}

.bg-dark {
	background-color: $gray-dark !important;
}

.bg-gray {
	background-color: $gray !important;
}

// Loop for contextual background color 
@each $type in $contextual-loop {
	.#{nth($type, 4)} {
		background-color: #{nth($type, 1)} !important;
	}
}	

.img-fit-cover {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.bg {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.container-fluid {

	&.container-fixed-lg {
		max-width: 1700px;
	}

	&.container-fixed-md {
		max-width: 1400px;
	}

	&.container-fixed-sm {
		max-width: 1280px;
	}
}

button,
input,
textarea {
	outline: none;
}

blockquote {
    border-left: 3px solid $gray-dark;
	padding-left: 30px;
	position: relative;
	font-family: serif;
    font-style: italic;
}

.blockquote-footer {
	color: $gray-dark;
}

dt {
	color: $gray-dark;
	font-weight: 500;
}

dd {
	margin-bottom: 10px;
}
	
.fade {
	&.in {
		opacity: 1;
	}
}	

.collapse {
	&.in {
		display: block;
	}
}

iframe {
	border: 0;

	@include screen-mobile {
		min-height: 175px !important;
		height: auto;
	}
}

.close {
	font-size: 18px;
}

@include print {
	body {
		font-size: 10px;
	}	

	.mrg-top-30,
	.mrg-top-20 {
		margin-top: 15px !important;
	}

	.print-invisible {
		display: none;
	}
}

