@font-face {
  font-family: "anticon";
  src:url("../fonts/anticon.eot");
  src:url("../fonts/anticon.eot?#iefix") format("embedded-opentype"),
    url("../fonts/anticon.woff") format("woff"),
    url("../fonts/anticon.ttf") format("truetype"),
    url("../fonts/anticon.svg#anticon") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "anticon" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.anticon {
  line-height: 0;
  vertical-align: -.125em;

  &:before {
    font-family: "anticon" !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }  
}

@keyframes loadingCircle{
	0% {
    @include transform-origin(50% 50%);
    @include transform(rotate(0));
	}
	100% {
		@include transform-origin(50% 50%);
    @include transform(rotate(360deg));
	}
}

.anticon-account-book:before {
  content: "\61";
}
.anticon-alert:before {
  content: "\62";
}
.anticon-alibaba:before {
  content: "\63";
}
.anticon-align-center:before {
  content: "\64";
}
.anticon-align-left:before {
  content: "\65";
}
.anticon-align-right:before {
  content: "\66";
}
.anticon-alipay:before {
  content: "\67";
}
.anticon-alipay-circle:before {
  content: "\68";
}
.anticon-aliwangwang:before {
  content: "\69";
}
.anticon-aliyun:before {
  content: "\6a";
}
.anticon-amazon:before {
  content: "\6b";
}
.anticon-android:before {
  content: "\6c";
}
.anticon-ant-cloud:before {
  content: "\6d";
}
.anticon-ant-design:before {
  content: "\6e";
}
.anticon-apartment:before {
  content: "\6f";
}
.anticon-api:before {
  content: "\70";
}
.anticon-apple:before {
  content: "\71";
}
.anticon-appstore:before {
  content: "\72";
}
.anticon-area-chart:before {
  content: "\73";
}
.anticon-arrow-down:before {
  content: "\74";
}
.anticon-arrow-left:before {
  content: "\75";
}
.anticon-arrow-right:before {
  content: "\76";
}
.anticon-arrow-up:before {
  content: "\77";
}
.anticon-arrows-alt:before {
  content: "\78";
}
.anticon-audio:before {
  content: "\79";
}
.anticon-audit:before {
  content: "\7a";
}
.anticon-backward:before {
  content: "\41";
}
.anticon-bank:before {
  content: "\42";
}
.anticon-bar-chart:before {
  content: "\43";
}
.anticon-barcode:before {
  content: "\44";
}
.anticon-bars:before {
  content: "\45";
}
.anticon-behance:before {
  content: "\46";
}
.anticon-behance-square:before {
  content: "\47";
}
.anticon-bell:before {
  content: "\48";
}
.anticon-bg-colors:before {
  content: "\49";
}
.anticon-block:before {
  content: "\4a";
}
.anticon-bold:before {
  content: "\4b";
}
.anticon-book:before {
  content: "\4c";
}
.anticon-border:before {
  content: "\4d";
}
.anticon-border-bottom:before {
  content: "\4e";
}
.anticon-border-horizontal:before {
  content: "\4f";
}
.anticon-border-inner:before {
  content: "\50";
}
.anticon-border-left:before {
  content: "\51";
}
.anticon-border-outer:before {
  content: "\52";
}
.anticon-border-right:before {
  content: "\53";
}
.anticon-border-top:before {
  content: "\54";
}
.anticon-border-verticle:before {
  content: "\55";
}
.anticon-box-plot:before {
  content: "\56";
}
.anticon-branches:before {
  content: "\57";
}
.anticon-build:before {
  content: "\58";
}
.anticon-bulb:before {
  content: "\59";
}
.anticon-calculator:before {
  content: "\5a";
}
.anticon-calendar:before {
  content: "\30";
}
.anticon-camera:before {
  content: "\31";
}
.anticon-car:before {
  content: "\32";
}
.anticon-caret-down:before {
  content: "\33";
}
.anticon-caret-left:before {
  content: "\34";
}
.anticon-caret-right:before {
  content: "\35";
}
.anticon-caret-up:before {
  content: "\36";
}
.anticon-carry-out:before {
  content: "\37";
}
.anticon-check:before {
  content: "\38";
}
.anticon-check-circle:before {
  content: "\39";
}
.anticon-check-square:before {
  content: "\21";
}
.anticon-chrome:before {
  content: "\22";
}
.anticon-ci:before {
  content: "\23";
}
.anticon-clock-circle:before {
  content: "\24";
}
.anticon-close:before {
  content: "\25";
}
.anticon-close-circle:before {
  content: "\26";
}
.anticon-close-square:before {
  content: "\27";
}
.anticon-cloud:before {
  content: "\28";
}
.anticon-cloud-download:before {
  content: "\29";
}
.anticon-cloud-server:before {
  content: "\2a";
}
.anticon-cloud-sync:before {
  content: "\2b";
}
.anticon-cloud-upload:before {
  content: "\2c";
}
.anticon-cluster:before {
  content: "\2d";
}
.anticon-code:before {
  content: "\2e";
}
.anticon-code-sandbox:before {
  content: "\2f";
}
.anticon-codepen:before {
  content: "\3a";
}
.anticon-codepen-circle:before {
  content: "\3b";
}
.anticon-coffee:before {
  content: "\3c";
}
.anticon-colum-height:before {
  content: "\3d";
}
.anticon-column-width:before {
  content: "\3e";
}
.anticon-compass:before {
  content: "\3f";
}
.anticon-contacts:before {
  content: "\40";
}
.anticon-container:before {
  content: "\5b";
}
.anticon-control:before {
  content: "\5d";
}
.anticon-copy:before {
  content: "\5e";
}
.anticon-copyright:before {
  content: "\5f";
}
.anticon-credit-card:before {
  content: "\60";
}
.anticon-crown:before {
  content: "\7b";
}
.anticon-customer-service:before {
  content: "\7c";
}
.anticon-dash:before {
  content: "\7d";
}
.anticon-dashboard:before {
  content: "\7e";
}
.anticon-database:before {
  content: "\5c";
}
.anticon-delete:before {
  content: "\e000";
}
.anticon-deployment-unit:before {
  content: "\e001";
}
.anticon-desktop:before {
  content: "\e002";
}
.anticon-diff:before {
  content: "\e003";
}
.anticon-dingding:before {
  content: "\e004";
}
.anticon-disconnect:before {
  content: "\e005";
}
.anticon-dislike:before {
  content: "\e006";
}
.anticon-dollar:before {
  content: "\e007";
}
.anticon-dot-chart:before {
  content: "\e008";
}
.anticon-double-left:before {
  content: "\e009";
}
.anticon-double-right:before {
  content: "\e00a";
}
.anticon-down:before {
  content: "\e00b";
}
.anticon-down-circle:before {
  content: "\e00c";
}
.anticon-down-square:before {
  content: "\e00d";
}
.anticon-download:before {
  content: "\e00e";
}
.anticon-drag:before {
  content: "\e00f";
}
.anticon-dribbble:before {
  content: "\e010";
}
.anticon-dribbble-square:before {
  content: "\e011";
}
.anticon-dropbox:before {
  content: "\e012";
}
.anticon-edit:before {
  content: "\e013";
}
.anticon-ellipsis:before {
  content: "\e014";
}
.anticon-enter:before {
  content: "\e015";
}
.anticon-environment:before {
  content: "\e016";
}
.anticon-euro:before {
  content: "\e017";
}
.anticon-exception:before {
  content: "\e018";
}
.anticon-exclamation:before {
  content: "\e019";
}
.anticon-exclamation-circle:before {
  content: "\e01a";
}
.anticon-experiment:before {
  content: "\e01b";
}
.anticon-export:before {
  content: "\e01c";
}
.anticon-eye:before {
  content: "\e01d";
}
.anticon-eye-invisible:before {
  content: "\e01e";
}
.anticon-facebook:before {
  content: "\e01f";
}
.anticon-fall:before {
  content: "\e020";
}
.anticon-fast-backward:before {
  content: "\e021";
}
.anticon-fast-forward:before {
  content: "\e022";
}
.anticon-file:before {
  content: "\e023";
}
.anticon-file-add:before {
  content: "\e024";
}
.anticon-file-done:before {
  content: "\e025";
}
.anticon-file-excel:before {
  content: "\e026";
}
.anticon-file-exclamation:before {
  content: "\e027";
}
.anticon-file-image:before {
  content: "\e028";
}
.anticon-file-jpg:before {
  content: "\e029";
}
.anticon-file-markdown:before {
  content: "\e02a";
}
.anticon-file-pdf:before {
  content: "\e02b";
}
.anticon-file-ppt:before {
  content: "\e02c";
}
.anticon-file-protect:before {
  content: "\e02d";
}
.anticon-file-search:before {
  content: "\e02e";
}
.anticon-file-sync:before {
  content: "\e02f";
}
.anticon-file-text:before {
  content: "\e030";
}
.anticon-file-unknown:before {
  content: "\e031";
}
.anticon-file-word:before {
  content: "\e032";
}
.anticon-file-zip:before {
  content: "\e033";
}
.anticon-filter:before {
  content: "\e034";
}
.anticon-fire:before {
  content: "\e035";
}
.anticon-flag:before {
  content: "\e036";
}
.anticon-folder:before {
  content: "\e037";
}
.anticon-folder-add:before {
  content: "\e038";
}
.anticon-folder-open:before {
  content: "\e039";
}
.anticon-font-colors:before {
  content: "\e03a";
}
.anticon-font-size:before {
  content: "\e03b";
}
.anticon-fork:before {
  content: "\e03c";
}
.anticon-form:before {
  content: "\e03d";
}
.anticon-forward:before {
  content: "\e03e";
}
.anticon-frown:before {
  content: "\e03f";
}
.anticon-fullscreen:before {
  content: "\e040";
}
.anticon-fullscreen-exit:before {
  content: "\e041";
}
.anticon-fund:before {
  content: "\e042";
}
.anticon-funnel-plot:before {
  content: "\e043";
}
.anticon-gateway:before {
  content: "\e044";
}
.anticon-gift:before {
  content: "\e045";
}
.anticon-github:before {
  content: "\e046";
}
.anticon-gitlab:before {
  content: "\e047";
}
.anticon-global:before {
  content: "\e048";
}
.anticon-gold:before {
  content: "\e049";
}
.anticon-google:before {
  content: "\e04a";
}
.anticon-google-plus:before {
  content: "\e04b";
}
.anticon-hdd:before {
  content: "\e04c";
}
.anticon-heart:before {
  content: "\e04d";
}
.anticon-heat-map:before {
  content: "\e04e";
}
.anticon-highlight:before {
  content: "\e04f";
}
.anticon-home:before {
  content: "\e050";
}
.anticon-hourglass:before {
  content: "\e051";
}
.anticon-html5:before {
  content: "\e052";
}
.anticon-idcard:before {
  content: "\e053";
}
.anticon-ie:before {
  content: "\e054";
}
.anticon-import:before {
  content: "\e055";
}
.anticon-inbox:before {
  content: "\e056";
}
.anticon-info:before {
  content: "\e057";
}
.anticon-info-circle:before {
  content: "\e058";
}
.anticon-instagram:before {
  content: "\e059";
}
.anticon-insurance:before {
  content: "\e05a";
}
.anticon-interation:before {
  content: "\e05b";
}
.anticon-issues-close:before {
  content: "\e05c";
}
.anticon-italic:before {
  content: "\e05d";
}
.anticon-key:before {
  content: "\e05e";
}
.anticon-laptop:before {
  content: "\e05f";
}
.anticon-layout:before {
  content: "\e060";
}
.anticon-left:before {
  content: "\e061";
}
.anticon-left-circle:before {
  content: "\e062";
}
.anticon-left-square:before {
  content: "\e063";
}
.anticon-like:before {
  content: "\e064";
}
.anticon-line:before {
  content: "\e065";
}
.anticon-line-chart:before {
  content: "\e066";
}
.anticon-line-height:before {
  content: "\e067";
}
.anticon-link:before {
  content: "\e068";
}
.anticon-linkedin:before {
  content: "\e069";
}
.anticon-loading:before {
  content: "\e06a";
  display: inline-block;
  @include animation('1s linear infinite loadingCircle') 
}
.anticon-loading-3-quarters:before {
  content: "\e06b";
}
.anticon-lock:before {
  content: "\e06c";
}
.anticon-login:before {
  content: "\e06d";
}
.anticon-logout:before {
  content: "\e06e";
}
.anticon-mail:before {
  content: "\e06f";
}
.anticon-man:before {
  content: "\e070";
}
.anticon-medicine-box:before {
  content: "\e071";
}
.anticon-medium:before {
  content: "\e072";
}
.anticon-medium-workmark:before {
  content: "\e073";
}
.anticon-meh:before {
  content: "\e074";
}
.anticon-menu:before {
  content: "\e075";
}
.anticon-menu-fold:before {
  content: "\e076";
}
.anticon-menu-unfold:before {
  content: "\e077";
}
.anticon-message:before {
  content: "\e078";
}
.anticon-minus:before {
  content: "\e079";
}
.anticon-minus-circle:before {
  content: "\e07a";
}
.anticon-minus-square:before {
  content: "\e07b";
}
.anticon-mobile:before {
  content: "\e07c";
}
.anticon-money-collect:before {
  content: "\e07d";
}
.anticon-monitor:before {
  content: "\e07e";
}
.anticon-mr:before {
  content: "\e07f";
}
.anticon-notification:before {
  content: "\e080";
}
.anticon-number:before {
  content: "\e081";
}
.anticon-ordered-list:before {
  content: "\e082";
}
.anticon-paper-clip:before {
  content: "\e083";
}
.anticon-pause:before {
  content: "\e084";
}
.anticon-pause-circle:before {
  content: "\e085";
}
.anticon-pay-circle:before {
  content: "\e086";
}
.anticon-percentage:before {
  content: "\e087";
}
.anticon-phone:before {
  content: "\e088";
}
.anticon-pic-center:before {
  content: "\e089";
}
.anticon-pic-left:before {
  content: "\e08a";
}
.anticon-pic-right:before {
  content: "\e08b";
}
.anticon-picture:before {
  content: "\e08c";
}
.anticon-pie-chart:before {
  content: "\e08d";
}
.anticon-play-circle:before {
  content: "\e08e";
}
.anticon-play-square:before {
  content: "\e08f";
}
.anticon-plus:before {
  content: "\e090";
}
.anticon-plus-circle:before {
  content: "\e091";
}
.anticon-plus-square:before {
  content: "\e092";
}
.anticon-pound:before {
  content: "\e093";
}
.anticon-poweroff:before {
  content: "\e094";
}
.anticon-printer:before {
  content: "\e095";
}
.anticon-profile:before {
  content: "\e096";
}
.anticon-project:before {
  content: "\e097";
}
.anticon-property-safety:before {
  content: "\e098";
}
.anticon-pushpin:before {
  content: "\e099";
}
.anticon-qq:before {
  content: "\e09a";
}
.anticon-qrcode:before {
  content: "\e09b";
}
.anticon-question:before {
  content: "\e09c";
}
.anticon-question-circle:before {
  content: "\e09d";
}
.anticon-radar-chart:before {
  content: "\e09e";
}
.anticon-radius-bottomleft:before {
  content: "\e09f";
}
.anticon-radius-bottomright:before {
  content: "\e0a0";
}
.anticon-radius-setting:before {
  content: "\e0a1";
}
.anticon-radius-upleft:before {
  content: "\e0a2";
}
.anticon-radius-upright:before {
  content: "\e0a3";
}
.anticon-read:before {
  content: "\e0a4";
}
.anticon-reconciliation:before {
  content: "\e0a5";
}
.anticon-red-envelope:before {
  content: "\e0a6";
}
.anticon-reddit:before {
  content: "\e0a7";
}
.anticon-redo:before {
  content: "\e0a8";
}
.anticon-reload:before {
  content: "\e0a9";
}
.anticon-reload-time:before {
  content: "\e0aa";
}
.anticon-rest:before {
  content: "\e0ab";
}
.anticon-retweet:before {
  content: "\e0ac";
}
.anticon-right:before {
  content: "\e0ad";
}
.anticon-right-circle:before {
  content: "\e0ae";
}
.anticon-right-square:before {
  content: "\e0af";
}
.anticon-rise:before {
  content: "\e0b0";
}
.anticon-robot:before {
  content: "\e0b1";
}
.anticon-rocket:before {
  content: "\e0b2";
}
.anticon-rollback:before {
  content: "\e0b3";
}
.anticon-safety:before {
  content: "\e0b4";
}
.anticon-safety-certificate:before {
  content: "\e0b5";
}
.anticon-save:before {
  content: "\e0b6";
}
.anticon-scan:before {
  content: "\e0b7";
}
.anticon-schedule:before {
  content: "\e0b8";
}
.anticon-scissor:before {
  content: "\e0b9";
}
.anticon-search:before {
  content: "\e0ba";
}
.anticon-security-scan:before {
  content: "\e0bb";
}
.anticon-select:before {
  content: "\e0bc";
}
.anticon-setting:before {
  content: "\e0bd";
}
.anticon-shake:before {
  content: "\e0be";
}
.anticon-share-alt:before {
  content: "\e0bf";
}
.anticon-shop:before {
  content: "\e0c0";
}
.anticon-shopping:before {
  content: "\e0c1";
}
.anticon-shopping-cart:before {
  content: "\e0c2";
}
.anticon-shrink:before {
  content: "\e0c3";
}
.anticon-sketch:before {
  content: "\e0c4";
}
.anticon-skin:before {
  content: "\e0c5";
}
.anticon-skype:before {
  content: "\e0c6";
}
.anticon-slack:before {
  content: "\e0c7";
}
.anticon-slack-square:before {
  content: "\e0c8";
}
.anticon-sliders:before {
  content: "\e0c9";
}
.anticon-small-dash:before {
  content: "\e0ca";
}
.anticon-smile:before {
  content: "\e0cb";
}
.anticon-snippets:before {
  content: "\e0cc";
}
.anticon-solution:before {
  content: "\e0cd";
}
.anticon-sort-ascending:before {
  content: "\e0ce";
}
.anticon-sort-descending:before {
  content: "\e0cf";
}
.anticon-sound:before {
  content: "\e0d0";
}
.anticon-star:before {
  content: "\e0d1";
}
.anticon-step-backward:before {
  content: "\e0d2";
}
.anticon-step-forward:before {
  content: "\e0d3";
}
.anticon-stock:before {
  content: "\e0d4";
}
.anticon-stop:before {
  content: "\e0d5";
}
.anticon-strikethrough:before {
  content: "\e0d6";
}
.anticon-swap:before {
  content: "\e0d7";
}
.anticon-swap-left:before {
  content: "\e0d8";
}
.anticon-swap-right:before {
  content: "\e0d9";
}
.anticon-switcher:before {
  content: "\e0da";
}
.anticon-sync:before {
  content: "\e0db";
}
.anticon-table:before {
  content: "\e0dc";
}
.anticon-tablet:before {
  content: "\e0dd";
}
.anticon-tag:before {
  content: "\e0de";
}
.anticon-tags:before {
  content: "\e0df";
}
.anticon-taobao:before {
  content: "\e0e0";
}
.anticon-taobao-circle:before {
  content: "\e0e1";
}
.anticon-team:before {
  content: "\e0e2";
}
.anticon-thunderbolt:before {
  content: "\e0e3";
}
.anticon-to-top:before {
  content: "\e0e4";
}
.anticon-tool:before {
  content: "\e0e5";
}
.anticon-trademark:before {
  content: "\e0e6";
}
.anticon-transaction:before {
  content: "\e0e7";
}
.anticon-trophy:before {
  content: "\e0e8";
}
.anticon-twitter:before {
  content: "\e0e9";
}
.anticon-underline:before {
  content: "\e0ea";
}
.anticon-undo:before {
  content: "\e0eb";
}
.anticon-unlock:before {
  content: "\e0ec";
}
.anticon-unordered-list:before {
  content: "\e0ed";
}
.anticon-up:before {
  content: "\e0ee";
}
.anticon-up-circle:before {
  content: "\e0ef";
}
.anticon-up-square:before {
  content: "\e0f0";
}
.anticon-upload:before {
  content: "\e0f1";
}
.anticon-usb:before {
  content: "\e0f2";
}
.anticon-user:before {
  content: "\e0f3";
}
.anticon-user-add:before {
  content: "\e0f4";
}
.anticon-user-delete:before {
  content: "\e0f5";
}
.anticon-usergroup-add:before {
  content: "\e0f6";
}
.anticon-usergroup-delete:before {
  content: "\e0f7";
}
.anticon-vertical-align-bottom:before {
  content: "\e0f8";
}
.anticon-vertical-align-middle:before {
  content: "\e0f9";
}
.anticon-vertical-align-top:before {
  content: "\e0fa";
}
.anticon-vertical-left:before {
  content: "\e0fb";
}
.anticon-vertical-right:before {
  content: "\e0fc";
}
.anticon-video-camera:before {
  content: "\e0fd";
}
.anticon-wallet:before {
  content: "\e0fe";
}
.anticon-warning:before {
  content: "\e0ff";
}
.anticon-wechat:before {
  content: "\e100";
}
.anticon-weibo:before {
  content: "\e101";
}
.anticon-weibo-circle:before {
  content: "\e102";
}
.anticon-weibo-square:before {
  content: "\e103";
}
.anticon-wifi:before {
  content: "\e104";
}
.anticon-windows:before {
  content: "\e105";
}
.anticon-woman:before {
  content: "\e106";
}
.anticon-yahoo:before {
  content: "\e107";
}
.anticon-youtube:before {
  content: "\e108";
}
.anticon-yuque:before {
  content: "\e109";
}
.anticon-zhihu:before {
  content: "\e10a";
}
.anticon-zoom-in:before {
  content: "\e10b";
}
.anticon-zoom-out:before {
  content: "\e10c";
}
.anticon-info-o:before {
  content: "\e10d";
}
.anticon-exclamation-o:before {
  content: "\e10e";
}
.anticon-close-o:before {
  content: "\e10f";
}
.anticon-check-o:before {
  content: "\e110";
}


