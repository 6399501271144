.select2-container {
	display: block;

	.select2-choice {
		height: $select-height;
		border: 1px solid $border-color;
		color: $body-color;
		line-height:  $select-height;
		background-image: none;
		background-color: $white;

		.select2-arrow {
			background-image: none;
			background-color: $white;
			border-left: 0;
			width: 25px;

			b {
				background: none;

				&:after {
					content: "\e00b";
					font-family: "anticon" ;
					color: rgba(0,0,0,.25);
				}
			}
		}
	}
}

.select2-container-active {
	
	&.select2-container {
		border: 1px solid $brand-primary;
		border-radius: $border-radius;
		box-shadow: $input-box-shadow-focus;

		.select2-choice,
		.select2-choices {
			box-shadow: none;
		}
	}
}

.select2-drop-active {
	border: 0px;
}

.select2-drop {
	color: $body-color;
	box-shadow: 0 2px 8px rgba(0,0,0,.15);

	&.select2-drop-above {

		&.select2-drop-active {
			border-top: 0px;
		}
	}
}


.select2-results {

	.select2-highlighted {
		color: $brand-primary;
		background-color: rgba($brand-primary, 0.1);
	}

	.select2-result-label {
		padding: 5px 12px;
	}
}


.select2-search {
	padding: 4px;

	&:after {
		content: "\e0ba";
		font-family: "anticon" ;
		color: $body-color;
		position: absolute;
		right: 15px;
		top: 10px;
	}
}

.select2-search input {
	border-color: $input-border-color;
	background: none;
	border-radius: $border-radius;
	font-family: $font-family-base;
}

.select2-dropdown-open {

	&.select2-container { 

		.select2-choice {

			.select2-arrow {

				b {
					&:after {
						content: "\e0ee";
					}
				}
			}
		}
	}

	&.select2-drop-above {
		.select2-choice,
		.select2-choices {
			background: none;
		}
	}
}

.select2-container-multi {

	.select2-choices {
		min-height: $select-height;
		border: 1px solid $border-color;
		background-image: none;

		.select2-search-choice {
			margin: 8px 0 8px 5px;
			border: 1px solid #e8e8e8;
			background-color: #fafafa;
			background-image: none;
		}
	}
}

.select2-search-choice-close {
	background-image: none;
	color: $gray-light;
	font-size: 12px;

	&:after {
		content: "\25";
		font-family: "anticon" ;
	}

	&:hover {
		color: $gray-dark;
	}
}
