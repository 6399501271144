.footer {
	padding: 0px $layout-content-gutter;
	font-size: 90%;	

	.footer-content {
		display: flex;
		height: $footer-height;
		align-items: center;
		border-top: 1px solid $border-color;

		@include screen-mobile-above {
			justify-content: space-between;
		}

		@include screen-mobile {
			flex-direction: column;
			padding-top: 15px;
		}
	}
}