.switch {
	input[type=checkbox] {
		opacity: 0;
		position: absolute;

		+label {
			position: relative;
		    display: inline-block;
		    @include transition(.4s ease);
		    height: $switch-height;
		    width: $switch-width;
			border: 1px solid $switch-bg;
			background-color: $switch-bg;
			border-radius: 60px;
			margin-bottom: 0px;
		    cursor: pointer;

		    &:before {
		    	content: "";
				position: absolute;
		        display: block;
				@include transition(0.2s cubic-bezier(0.24, 0, 0.5, 1));
				height: $switch-height;
				width: $switch-width;
				top: 0;
				left: 0;
				border-radius: 30px;
		    }

		    &:after {
		    	content: "";
				position: absolute;
				display: block;
				box-shadow: 0 2px 4px 0 rgba(0,35,11,.2);
				@include transition(0.36s cubic-bezier(.78,.14,.15,.86));
				background: $white;
				height: $switch-dot-size;
				width: $switch-dot-size;
				top: 1px;
				left: 1px;
				border-radius: 60px;
		    }
		}

		&:checked {

			+label {
				background: $switch-theme-color;
				border-color: $switch-theme-color;

				&:before {
					@include transition(width 0.2s cubic-bezier(0, 0, 0, 0.1));
				}

				&:after {
					left: $switch-gutter;
				}
			}

			&:active {
				&:not(:disabled){
					+label {
						&:after {
							width: $switch-dot-size + $switch-dot-actived-size;
							left: $switch-gutter - $switch-dot-actived-size;
						}
					}
				}
			}
		}

		&:not(:checked) {
			&:active {

				&:not(:disabled){
					+label {
						&:after {
							width: $switch-dot-size + $switch-dot-actived-size;
						}
					}
				}
			}
		}

		&:disabled {
			+label {
				opacity: 0.6;
				cursor: not-allowed;
			}
		}
	}
}