.datepicker {
	border-radius: 0px;
	padding: 20px;
	box-shadow: none;
	border: 1px solid $border-color;
	border-radius: $border-radius;

	&.datepicker-dropdown {
		z-index: $zindex-popover !important;
	}
	
	table {

		tr {

			th,
			td {
				border-radius: 50px;
				width: $datepicker-day-size;
				height: $datepicker-day-size;
			}

			th {
				font-weight: $font-weight-semibold;
			}

			td {
				@include transition(all 0.2s ease-in-out); 

				span {
					border-radius: $border-radius;
				}

				&.old,
				&.new {
					color: rgba($body-color, 0.25);
				}
			}
		}
	}	
}
.datepicker table tr td span.active:active, 
.datepicker table tr td span.active:hover:active, 
.datepicker table tr td span.active.disabled:active, 
.datepicker table tr td span.active.disabled:hover:active, 
.datepicker table tr td span.active.active, 
.datepicker table tr td span.active:hover.active, 
.datepicker table tr td span.active.disabled.active, 
.datepicker table tr td span.active.disabled:hover.active,
.datepicker table tr td span.active.active:hover,
.datepicker table tr td span.active:hover.active:hover,
.datepicker table tr td.active:active,
.datepicker table tr td.active.highlighted:active, 
.datepicker table tr td.active.active, 
.datepicker table tr td.active.highlighted.active,
.datepicker table tr td.active:active:hover, 
.datepicker table tr td.active.highlighted:active:hover, 
.datepicker table tr td.active.active:hover, 
.datepicker table tr td.active.highlighted.active:hover, 
.datepicker table tr td.active:active:focus, 
.datepicker table tr td.active.highlighted:active:focus, 
.datepicker table tr td.active.active:focus, 
.datepicker table tr td.active.highlighted.active:focus, 
.datepicker table tr td.active:active.focus, 
.datepicker table tr td.active.highlighted:active.focus, 
.datepicker table tr td.active.active.focus, 
.datepicker table tr td.active.highlighted.active.focus {
	color: $white;
    background: $brand-primary;
    border-color: transparent;
}

.datepicker table tr td span:hover,
.datepicker table tr td span.focused {
	background: $brand-primary;
	color: $white;
}


.datepicker table tr td.day:hover, 
.datepicker table tr td.focused {	
	background: rgba($brand-primary,0.1);
	color: $brand-primary;
	font-weight: $font-weight-semibold;
    cursor: pointer;
}

.datepicker .datepicker-switch:hover, 
.datepicker .prev:hover, 
.datepicker .next:hover, 
.datepicker tfoot tr th:hover {
	color: $brand-primary;
	background-color: transparent;
    cursor: pointer;
}

.datepicker-inline {
	width: $datepicker-inline-width;
}

.daterangepicker {
	border-radius: 0px;
	padding: 30px;
	box-shadow: none;
	border: 1px solid $border-color;

	.input-mini {
		border-radius: 0px;
	    margin-bottom: 20px;
	    height: 40px;
	    padding: 0 6px 0 35px;

	    &.active {
	    	border-radius: 0px;
	    	border-color: lighten($brand-primary, 20%);		
	    }
	}

	.daterangepicker_input {
		i {
			position: absolute;
			left: 10px;
    		top: 13px;
		}
	}

	td,
	th {
		border-radius: $border-radius;
		width: 40px;
		height: 35px;
		
		&.available{
			&:hover{
				background: $gray-dark;
			}	
		}
	}

	td {
		&.in-range {
			background-color: transparent;
			color: $brand-primary;
		}

		&.active {
			background-color: $brand-primary;
		    border-color: transparent;
		    color: $white;

			&:hover {
				background-color: $brand-primary;
			    border-color: transparent;
			    color: $white;
			}
		}

		&.start-date {
			border-radius: 0px;

			&.end-date {
				border-radius: 0px;
			}
		}

		&.end-date {
			border-radius: 0px;
		}

	}

	select {
		&.hourselect,
		&.minuteselect,
		&.secondselect,
		&.ampmselect {
			border: 1px solid $border-color;
			min-height: 30px;
		}
	}

	.calendar-time {
		i {
			top: 8px;
  		    left: 35px;
		}
	}

	@include screen-mobile-above {
		.calendar {
			margin-right: 20px !important;		
		}
	} 
}

