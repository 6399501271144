.star-rating {
    display: flex;
    justify-content: flex-end;
    flex-direction: row-reverse;

    > input {
        display: none;

        &:checked {

            ~ label {
                color: $brand-warning;
            }
        }

        &:not(:disabled) {
            &:hover {

                ~label {
                    color: $brand-warning;
                }
            }
        }
    } 

    > label {
        color: $gray-lighter; 

        &:before { 
            content: "\f005"; 
            margin-right: 8px;
            font-size: 18px;
            font-family: 'Font Awesome 5 Free';
            font-weight: 900;
        }
    }
}