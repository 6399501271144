@import '~bootstrap-icons/font/bootstrap-icons';
@import "~toastify-js/src/toastify.css";
@import 'mixins';
@import '~filepond/dist/filepond.min.css';
@import '~filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
//                           mixins
@import 'variables';
//                        variables

/*---------------------------------
-------- Depdencies Styles --------
---------------------------------*/
@import 'plugins/bootstrap';
//                 bootstrap
@import 'plugins/perfect-scroll-bar';
//        perfect-scroll-bar
@import 'plugins/nouislider';
//                nouislider
@import 'plugins/datatables';
//                datatables
@import 'plugins/select2';
//                   select2
@import 'plugins/datepicker';
//                datepicker
@import 'plugins/quill';
//                     quill
//@import 'plugins/chartist';//                  chartist
@import 'plugins/chartjs';
//                   chartjs

// Dependencies for Demo
@import 'plugins/prism';
//                     prism

/*---------------------------------
-------------- common -------------
---------------------------------*/
@import 'common/general';
//                   general
@import 'common/utilities';
//                 utilities

/*---------------------------------
-------------- Template -------------
---------------------------------*/
@import 'template/side-nav';
//                side-nav
@import 'template/quick-view';
//              quick-view
@import 'template/header';
//                  header
@import 'template/footer';
//                  footer
@import 'template/page-container';
//          page-container

/*---------------------------------
------------ Ui Element -----------
---------------------------------*/
@import 'ui-elements/avatar';
//               avatar
@import 'ui-elements/icons';
//                icons
@import 'ui-elements/anticon';
//              anticon
@import 'ui-elements/font-awesome';
//         font-awesome
@import 'ui-elements/breadcrumb';
//           breadcrumb
@import 'ui-elements/buttons';
//              buttons
@import 'ui-elements/cards';
//                cards
@import 'ui-elements/list';
//                 list
@import 'ui-elements/alert';
//                alert
@import 'ui-elements/badge';
//                badge
@import 'ui-elements/pagination';
//           pagination
@import 'ui-elements/timeline';
//             timeline

/*---------------------------------
------------ Components -----------
---------------------------------*/
@import 'components/accordion';
//              accordion
@import 'components/modal';
//                  modal
@import 'components/dropdown';
//               dropdown
@import 'components/tooltips';
//               tooltips
@import 'components/popovers';
//               popovers
@import 'components/tabs';
//                   tabs
@import 'components/carousel';
//               carousel
@import 'components/toasts';
//                 toasts
@import 'components/progress-bar';
//           progress-bar
@import 'components/menu';
//                   menu

/*---------------------------------
------------- Tables --------------
---------------------------------*/
@import 'tables/tables';
//                     tables

/*---------------------------------
-------------- Forms --------------
---------------------------------*/
@import 'forms/input';
//                       input
@import 'forms/select';
//                      select
@import 'forms/checkbox';
//                    checkbox
@import 'forms/radio';
//                       radio
@import 'forms/switch';
//                      switch
@import 'forms/star_rating';
//                 star_rating
@import 'forms/validation';
//                  validation

/*---------------------------------
-------------- Apps ---------------
---------------------------------*/
@import 'apps/mail';
//                         mail
@import 'apps/file-manager';
//                 file-manager
@import 'apps/chat';
//                         chat
@import 'apps/e-commerce';
//                   e-commerce

/*---------------------------------
----------- Dashboard -------------
---------------------------------*/
@import 'dashboard/projects.scss';
//           projects

/*---------------------------------
-------- Theme Configurator -------
---------------------------------*/
@import 'theme-configurator/theme-configurator';

